<template>
  <div>
    <!-- 头框 -->
    <div class="navHrader">
      <!-- @click="userModification" -->
      <div class="centerdiv flx">
        <div class="flx1" style="margin-top: 0.1rem">
          <!-- 用户头像 -->
          <div class="headerImg" style="margin-top: 0.1rem">
            <img :src="userInfo.UserImage" />
          </div>
          <!-- 个人信息 -->
          <div class="userInfo">
            <div class="userName">{{ userInfo.name }}</div>
            <div class="userNumber">编号：{{ userInfo.no }}</div>
          </div>
        </div>
      </div>
      <div style="height: 0.3rem; width: 100%"></div>
    </div>

    <!-- 基础功能 -->
    <div style="margin-top: 0.3rem">
      <div class="functionstyle">
        <div class="list flx" @click="yeskip">
          <!-- 名称 -->
          <div class="textstyles">解除绑定</div>
          <!-- icon图标 -->
          <div>
            <van-icon style="line-height: 1rem" name="arrow" size="15" color="#cecece" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <van-skeleton title avatar :row="3" :loading="true">
    <div>实际内容</div>
  </van-skeleton>
</template>
<script>
// 设置全局背景色
import color from "@/components/componentsJs/color";
import { Dialog } from "vant";

import {
  onMounted,
  ref,
  getCurrentInstance,
  reactive,
  watch,
  toRefs,
} from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    color.colormounted();

    // 常用功能
    const useRouteone = useRoute();
    // vue 调用main.js 执行全局函数
    const { proxy } = getCurrentInstance();
    const reactiveParams = reactive({
      userInfo: "",
      show: false,
    });

    // 获取用户个人信息
    const getUserInfo = () => {
      reactiveParams.userInfo = useRouteone.query.userInfo
        ? JSON.parse(useRouteone.query.userInfo)
        : sessionStorage.getItem("userInfo")
          ? JSON.parse(sessionStorage.getItem("userInfo"))
          : JSON.parse(localStorage.getItem("userInfo"));
      reactiveParams.userInfo.UserImage =
        "http://www.xinhuanet.com/photo/titlepic/112823/1128234289_1641365131552_title0h.jpg";
    };
    // 个人信息修改
    function userModification() {
      let userinfoJson = JSON.stringify(reactiveParams.userInfo);
      uni.postMessage({
        data: {
          userinfo: userinfoJson,
          skipurl: "/meinfo",
        },
      });
      proxy.$router.push({
        name: "meinfo",
        params: { userinfo: userinfoJson },
      });
      console.log(proxy.$router);
    }
    // 解除绑定
    const yeskip = async () => {
      Dialog.confirm({
        title: "标题",
        message: "是否确认解除绑定！！！",
      })
        .then(async () => {
          await proxy.$Api
            .get(proxy.$MeApi.unBind, {
              no: reactiveParams.userInfo.no,
            })
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                reactiveParams.show = false;

                uni.navigateTo({
                  url: "/pages/yeskip/index?urls=" + "/login",
                });
              }
            });
        })
        .catch(async () => {
          reactiveParams.show = false;
        });
    };
    onMounted(() => {
      getUserInfo();
    });
    return {
      ...toRefs(reactiveParams),
      useRouteone,
      // 点击修改页面
      userModification,
      // 页面跳转
      yeskip,
      getUserInfo,
    };
  },
};
</script>
<style lang="scss" scoped>
#app {
  background: #f4f4f4;
}

// 头部组件样式
.backgroundcolor {
  background-color: #fff;
}

// 公共
.common {
  width: 90%;
  margin: 0 auto;
}

@mixin textstyle($size, $font) {
  font-size: $size;
  font-weight: $font;
}

.icon {
  @extend .common;
  padding-right: 0.4rem;
  margin-top: 0.1rem;
}

@mixin stylebasics($width, $height) {
  width: $width;
  height: $height;
}

.navHrader {
  width: 100%;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  @extend .backgroundcolor;

  .centerdiv {
    width: 90%;
    margin: 0 auto;

    // 头像图片样式
    .headerImg {
      @include stylebasics(1rem, 1rem);

      img {
        @include stylebasics(100%, 100%);
        border-radius: 50%;
        overflow: hidden;
      }
    }

    // 用户信息
    .userInfo {
      margin-top: 0.2rem;
      margin-left: 0.3rem;

      .userName {
        font-size: 0.3rem;
        font-weight: 600;
        color: #333;
      }

      .userNumber {
        font-size: 0.25rem;
        color: #cecece;
        margin-top: 0.1rem;
      }
    }

    // 右侧图标
    .IconRight {
      overflow: hidden;
      box-sizing: border-box;
    }
  }
}

//
.functionstyle {
  width: 100%;
  height: 1rem;
  @extend .backgroundcolor;

  .list {
    width: 90%;
    font-size: 0.25rem;
    margin: 0 auto;
    margin-top: 0.1rem;

    .textstyles {
      @include textstyle(0.25rem, 600);
      line-height: 1rem;
    }
  }
}
</style>
